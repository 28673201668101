/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : ionic6Template Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : ionic6Template Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgOtpInputModule } from 'ng-otp-input';
import { Camera } from '@ionic-native/Camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Uid } from '@ionic-native/uid/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocationStrategy, PathLocationStrategy,HashLocationStrategy } from '@angular/common';
import { AngularFireModule }  from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDTqz2SXY8HocashxKnTyh9-aQkq_QE38k",
  authDomain: "sgtta-959b0.firebaseapp.com",
  databaseURL: "https://sgtta-959b0-default-rtdb.firebaseio.com",
  projectId: "sgtta-959b0",
  storageBucket: "sgtta-959b0.appspot.com",
  messagingSenderId: "199585517203",
  appId: "1:199585517203:web:0f13e24060553405c48766",
  measurementId: "G-TGVWY9DG4N"
};


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, NgOtpInputModule, HttpClientModule,AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule],
  providers: [Camera, File, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, DocumentViewer, FileOpener, FileTransfer, Uid, AndroidPermissions],
  bootstrap: [AppComponent],
})
export class AppModule { }
