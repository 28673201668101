import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PUT,OPTIONS,DELETE',
    'Access-Control-Allow-Credentials': 'true'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  //apiurl = "http://localhost:8080/api/";
  //apiurl = "https://sgttaapi.airconcollabs.com/api/";
  apiurl = "https://api.sgtta.org/api/";
  constructor(private http: HttpClient) { }

  sendotp(MobileNo: string): Observable<any> {
    return this.http.get(this.apiurl + "user/checkandsendotp?MobileNo=" + MobileNo, httpOptions);
  }
  
  checkuser(MobileNo: string): Observable<any> {
    return this.http.get(this.apiurl + "user/checkuser?MobileNo=" + MobileNo, httpOptions);
  }

  verifyotp(MobileNo: string, otp: string,DeviceId:any): Observable<any> {
    return this.http.get(this.apiurl + "user/verifyotp?MobileNo=" + MobileNo + "&OTP=" + otp +"&DeviceId=" + DeviceId, httpOptions);
  }

  logoutuser(UserId: any,DeviceId:any): Observable<any> {
    return this.http.get(this.apiurl + "user/logoutuser?UserId=" + UserId+ "&DeviceId="+ DeviceId, httpOptions);
  }

  getcategorylist(): Observable<any> {
    return this.http.get(this.apiurl + "category/GetCategoryList", httpOptions);
    //return this.http.get("https://sgttaapi.airconcollabs.com/api/category/GetCategoryList", httpOptions);
  }

  getproductlist(): Observable<any> {
    return this.http.get(this.apiurl + "subcategory/GetProductList", httpOptions);
  }

  getstatelist(): Observable<any> {
    return this.http.get(this.apiurl + "auth/GetStateList", httpOptions);
  }

  getcitylist(StateName: any): Observable<any> {
    return this.http.get(this.apiurl + "auth/GetCityList?StateName=" + StateName, httpOptions);
  }

  GetSubcatbyCatId(CategoryId: any): Observable<any> {
    return this.http.get(this.apiurl + "subcategory/GetSubcatbyCatId?CategoryId=" + CategoryId, httpOptions);
  }

  checkgstin(GSTIN: any): Observable<any> {
    return this.http.get(this.apiurl + "user/checkgstin?GSTIN=" + GSTIN, httpOptions);
  }

  addBusiness(t: { UserId: any; RegisteredMobileNo: any; Name:any; MobileNo: any; State: any; City: any; CategoryId: any; SubCategoryId: any; IsGST: any; GSTIN: any; PanCard: any; LegalName: any; FirmName: any; RegisteredAddress: any; GSTRegDate: any; BrandName: any; BusinessStartDate: any; Address: any; ContactPerson: any; CreatedBy: any; RefBy: any; }, BusinessImage: any, BusinessImg: any, Branchlist: any): Observable<any> {
    return this.http.post(this.apiurl + "business/addBusiness", {
      RegisteredMobileNo: t.RegisteredMobileNo,
      Name: t.Name,
      MobileNo: t.MobileNo,
      State: t.State,
      City: t.City,
      CategoryId: t.CategoryId,
      SubCategoryId: t.SubCategoryId != '' && t.SubCategoryId != undefined && t.SubCategoryId != null ? t.SubCategoryId : 0,
      IsGST: t.IsGST,
      GSTIN: t.GSTIN,
      PanCard: t.PanCard,
      LegalName: t.LegalName,
      FirmName: t.FirmName,
      RegisteredAddress: t.RegisteredAddress,
      GSTRegDate: t.GSTRegDate,
      BrandName: t.BrandName,
      BusinessStartDate: t.BusinessStartDate,
      Address: t.Address,
      ContactPerson: t.ContactPerson,
      CreatedBy: t.CreatedBy,
      BusinessImage: BusinessImage,
      BusinessImg: BusinessImg,
      Branchlist: Branchlist,
      RefBy: t.RefBy,
      UserId: t.UserId
    }, httpOptions);
  }

  addCustomer(t: { RegisteredMobileNo: any; Name:any; UserId: any; MobileNo: any; State: any; City: any; CategoryId: any; SubCategoryId: any; IsGST: any; GSTIN: any; PanCard: any; LegalName: any; FirmName: any; RegisteredAddress: any; GSTRegDate: any; BrandName: any; BusinessStartDate: any; Address: any; ContactPerson: any; CreatedBy: any; RefBy: any; }, BusinessImage: any, BusinessImg: any, Branchlist: any): Observable<any> {
    return this.http.post(this.apiurl + "business/addCustomer", {
      RegisteredMobileNo: t.RegisteredMobileNo,
      Name: t.Name,
      MobileNo: t.MobileNo,
      State: t.State,
      City: t.City,
      CategoryId: t.CategoryId,
      SubCategoryId: t.SubCategoryId != '' && t.SubCategoryId != undefined && t.SubCategoryId != null ? t.SubCategoryId : 0,
      IsGST: t.IsGST,
      GSTIN: t.GSTIN,
      PanCard: t.PanCard,
      LegalName: t.LegalName,
      FirmName: t.FirmName,
      RegisteredAddress: t.RegisteredAddress,
      GSTRegDate: t.GSTRegDate,
      BrandName: t.BrandName,
      BusinessStartDate: t.BusinessStartDate,
      Address: t.Address,
      ContactPerson: t.ContactPerson,
      CreatedBy: t.CreatedBy,
      BusinessImage: BusinessImage,
      BusinessImg: BusinessImg,
      Branchlist: Branchlist,
      RefBy: t.RefBy,
      UserId: t.UserId
    }, httpOptions);
  }


  GetBusinessListByCatId(CategoryId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetBusinessListByCatId?CategoryId=" + CategoryId, httpOptions);
  }

  GetBusinessListBySubcatId(SubCategoryId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetBusinessListBySubcatId?SubCategoryId=" + SubCategoryId, httpOptions);
  }

  GetBusinessListbyBusinessId(BusinessId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetBusinessListbyBusinessId?BusinessId=" + BusinessId, httpOptions);
  }

  GetBranchListbyBusinessId(BusinessId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetBranchListbyBusinessId?BusinessId=" + BusinessId, httpOptions);
  }

  addRating(t: { UserId: any; BusinessId: any; Payment: any; TurnOver: any; GoodsReturn: any; Behaviour: any; CreditLimits: any; RatingDate: any; Comments:any;}): Observable<any> {
    return this.http.post(this.apiurl + "business/addRating", {
      UserId: t.UserId,
      BusinessId: t.BusinessId,
      Payment: t.Payment,
      TurnOver: t.TurnOver,
      GoodsReturn: t.GoodsReturn,
      Behaviour: t.Behaviour,
      CreditLimits: t.CreditLimits,
      RatingDate: t.RatingDate,
      Comments:t.Comments
    }, httpOptions);
  }

  verifyGSTIN(gstin: any): Observable<any> {
    return this.http.get(this.apiurl + "business/verifyGSTIN?gstin=" + gstin, httpOptions);
  }

  UserById(UserId: any): Observable<any> {
    return this.http.get(this.apiurl + "user/findById?UserId=" + UserId, httpOptions);
  }

  Updateuser(t: { UserId: any; Name: any; CreatedBy: any; Email: any; }, ProfileImage: any, ProfileImg: any): Observable<any> {
    return this.http.post(this.apiurl + "user/update", {
      Name: t.Name,
      CreatedBy: t.CreatedBy,
      ProfileImage: ProfileImage,
      ProfileImg: ProfileImg,
      UserId: t.UserId,
      Email: t.Email
    }, httpOptions);
  }

  GetMyRatingList(UserId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetMyRatingList?UserId=" + UserId, httpOptions);
  }

  GetMyBusinessList(UserId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetMyBusinessList?UserId=" + UserId, httpOptions);
  }

  addSelfBusiness(t: { UserId: any; MobileNo: any; State: any; City: any; CategoryId: any; SubCategoryId: any; IsGST: any; GSTIN: any; PanCard: any; LegalName: any; FirmName: any; RegisteredAddress: any; GSTRegDate: any; BrandName: any; BusinessStartDate: any; Address: any; ContactPerson: any; CreatedBy: any; RefBy: any; }, BusinessImage: any, BusinessImg: any, Branchlist: any): Observable<any> {
    return this.http.post(this.apiurl + "business/addSelfBusiness", {
      MobileNo: t.MobileNo,
      State: t.State,
      City: t.City,
      CategoryId: t.CategoryId,
      SubCategoryId: t.SubCategoryId != '' && t.SubCategoryId != undefined && t.SubCategoryId != null ? t.SubCategoryId : 0,
      IsGST: t.IsGST,
      GSTIN: t.GSTIN,
      PanCard: t.PanCard,
      LegalName: t.LegalName,
      FirmName: t.FirmName,
      RegisteredAddress: t.RegisteredAddress,
      GSTRegDate: t.GSTRegDate,
      BrandName: t.BrandName,
      BusinessStartDate: t.BusinessStartDate,
      Address: t.Address,
      ContactPerson: t.ContactPerson,
      CreatedBy: t.CreatedBy,
      BusinessImage: BusinessImage,
      BusinessImg: BusinessImg,
      Branchlist: Branchlist,
      RefBy: t.RefBy,
      UserId: t.UserId
    }, httpOptions);
  }

  ProductListbyBusinessId(BusinessId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/ProductListbyBusinessId?BusinessId=" + BusinessId, httpOptions);
  }

  getsupport(): Observable<any> {
    return this.http.get(this.apiurl + "support/get", httpOptions);
  }

  GetRatingByBusinessId(BusinessId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetRatingByBusinessId?BusinessId=" + BusinessId, httpOptions);
  }

  GetLatestNews(): Observable<any> {
    return this.http.get(this.apiurl + "news/GetLatestNews", httpOptions);
  }

  GetBusinessListFilter(gst: any,mobileno: any,name: any,state: any,city: any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetBusinessListFilter?gst=" + gst+ "&mobileno="+ mobileno+"&name=" + name + "&state=" + state + "&city=" + city, httpOptions);
  }

  GetEventByType(type: any): Observable<any> {
    return this.http.get(this.apiurl + "event/GetEventByType?type=" + type, httpOptions);
  }

  getabout(): Observable<any> {
    return this.http.get(this.apiurl + "about/get", httpOptions);
  }

  getaboutbanner(): Observable<any> {
    return this.http.get(this.apiurl + "about/getaboutbanner", httpOptions);
  }

  getaboutmember(): Observable<any> {
    return this.http.get(this.apiurl + "about/getaboutmember", httpOptions);
  }

  getbanner(): Observable<any> {
    return this.http.get(this.apiurl + "about/getbanner", httpOptions);
  }

  GetProductListAdmin(): Observable<any> {
    return this.http.get(this.apiurl + "subcategory/GetProductListAdmin", httpOptions);
  }

  GetPrimaryBusinessbyUserId(UserId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetPrimaryBusinessbyUserId?UserId=" + UserId, httpOptions);
  }

  SetPrimaryBusiness(UserId: any, BusinessId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/SetPrimaryBusiness?UserId=" + UserId + "&BusinessId=" + BusinessId, httpOptions);
  }

  UpdateBusinessImage(BusinessImage: any, BusinessImg: any, BusinessId: any): Observable<any> {
    return this.http.post(this.apiurl + "business/UpdateBusinessImage", {
      BusinessImage: BusinessImage,
      BusinessImg: BusinessImg,
      BusinessId: BusinessId
    }, httpOptions);
  }

  deletebranch(BBId: any): Observable<any> {
    return this.http.get(this.apiurl + "business/deletebranch?BBId=" + BBId, httpOptions);
  }


  UpdateSelfBusiness(BrandName: any, BusinessStartDate: any, Address: any, ContactPerson: any, CreatedBy: any, MobileNo: any, BusinessId: any, Branchlist: any, DBranchlist: any): Observable<any> {
    return this.http.post(this.apiurl + "business/UpdateSelfBusiness", {
      BrandName: BrandName,
      BusinessStartDate: BusinessStartDate,
      Address: Address,
      ContactPerson: ContactPerson,
      CreatedBy: CreatedBy,
      MobileNo: MobileNo,
      BusinessId: BusinessId,
      Branchlist: Branchlist,
      DBranchlist: DBranchlist
    }, httpOptions);
  }
  
  GetBusinessListFilterAll(CategoryId: any,State:any,City:any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetBusinessListFilterAll?CategoryId=" + CategoryId + "&State="+ State + "&City="+ City, httpOptions);
  }

  GetCategoryforRatinglistByCatId(CategoryId: any): Observable<any> {
    return this.http.get(this.apiurl + "category/GetCategoryforRatinglistByCatId?CategoryId=" + CategoryId, httpOptions);
  }
  
  GetCommentListByBusinessId(BusinessId: any,limit:any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetCommentListByBusinessId?BusinessId=" + BusinessId + "&limit="+ limit, httpOptions);
  }
  
  GetDisputebyStatus(Status: any): Observable<any> {
    return this.http.get(this.apiurl + "dispute/GetDisputebyStatus?Status=" + Status, httpOptions);
  }
  
  getfaq(): Observable<any> {
    return this.http.get(this.apiurl + "faq/getfaq", httpOptions);
  }

  addusernote(NoteId: any, UserId: any): Observable<any> {
    return this.http.post(this.apiurl + "note/addusernote", {
      NoteId: NoteId,
      UserId: UserId
    }, httpOptions);
  }

  GetNoteByType(type: any,UserId: any): Observable<any> {
    return this.http.get(this.apiurl + "note/GetNoteByType?type=" + type + "&UserId="+ UserId, httpOptions);
  }
  
  findnotifybyid(NoteId: any): Observable<any> {
    return this.http.get(this.apiurl + "note/findnotifybyid?NoteId="+ NoteId, httpOptions);
  }

  GetNewsYear(): Observable<any> {
    return this.http.get(this.apiurl + "news/GetNewsYear", httpOptions);
  }

  GetNewsFilter(month:any,year:any): Observable<any> {
    return this.http.get(this.apiurl + "news/GetNewsFilter?month=" + month + "&year=" + year, httpOptions);
  }

  GetBusinessListFilterCategory(gst: any,mobileno: any,name: any,state: any,city: any,categoryid:any): Observable<any> {
    return this.http.get(this.apiurl + "business/GetBusinessListFilterCategory?gst=" + gst+ "&mobileno="+ mobileno+"&name=" + name + "&state=" + state + "&city=" + city + "&categoryid=" + categoryid, httpOptions);
  }

  CheckRating(BusinessId: any,UserId:any,CategoryId:any,RateCatId:any): Observable<any> {
    return this.http.get(this.apiurl + "business/CheckRating?BusinessId=" + BusinessId + "&UserId="+ UserId + "&CategoryId=" + CategoryId + "&RateCatId=" + RateCatId, httpOptions);
  }
  
}
