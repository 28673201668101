/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : ionic6Template Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { Component, OnInit, Optional, ViewChildren, QueryList, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { IonRouterOutlet, Platform, AlertController, MenuController } from '@ionic/angular';
import { UserService } from '../app/services/user.service';
import { UtilService } from '../app/services/util.service';
import { Location } from '@angular/common';
import { Device } from '@capacitor/device';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  menuflag: boolean = false;
  profileimage = '../../../assets/images/user.png';
  mobileno;
  name;
  joindate;
  interval: any;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  UniqueDeviceID;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;


  constructor(private router: Router,
    private platform: Platform,
    private userService: UserService,
    private utilService: UtilService,
    public alertCtrl: AlertController,
    public loc: Location,
    public menuCtrl: MenuController,
    private androidPermissions: AndroidPermissions,
    @Optional() private routerOutlet?: IonRouterOutlet
   
  ) {

    console.log(localStorage.getItem('MobileNo'));
    this.initializeApp();
    this.binddata();
    this.backButtonEvent();
    //this.getPermission();
    //this.getUniqueDeviceID();

    // this.interval = setInterval(() => {
    //   this.binddata();
    // }, 3000);
    // setTimeout(()=>{
    //   SplashScreen.hide();
    // },5000);
  }

  ngOnInit() {
    //this.initializeApp();
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  openclosemenu() {

    this.menuCtrl.enable(false);
    this.menuCtrl.close();

  }

  ionViewCanEnter() {
    //this.initializeApp();
  }

  async appexistalert() {
    const alert = await this.alertCtrl.create({
      header: 'Exit App',
      subHeader: 'Confirm',
      message: 'Are you sure you want to exit app?',
      buttons: [{ text: 'NO', role: 'cancel' }, { text: 'YES', role: 'confirm', handler: () => { navigator['app'].exitApp(); } }]
    });
    await alert.present();
  }

  backButtonEvent() {
    //this.utilService.success_msg(JSON.stringify(this.routerOutlets));
    document.addEventListener("backbutton", () => {
      this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
        if (outlet && outlet.canGoBack()) {
          if (this.router.url == "/home") {
            navigator['app'].exitApp();
          }
          else
            outlet.pop();
        } else if (this.router.url === "tabs/tab1") {
          navigator['app'].exitApp(); // work for ionic 4
        } else if (!outlet.canGoBack()) {
          navigator['app'].exitApp(); // work for ionic 4
        }
      });
    });
  }

  // backButtonEvent() {
  //   this.platform.backButton.subscribeWithPriority(10,(processNextHandler) => {
  //     // close action sheet
  //     // try {
  //     //     const element = await this.actionSheetCtrl.getTop();
  //     //     if (element) {
  //     //         element.dismiss();
  //     //         return;
  //     //     }
  //     // } catch (error) {
  //     // }

  //     // // close popover
  //     // try {
  //     //     const element = await this.popoverCtrl.getTop();
  //     //     if (element) {
  //     //         element.dismiss();
  //     //         return;
  //     //     }
  //     // } catch (error) {
  //     // }

  //     // // close modal
  //     // try {
  //     //     const element = await this.modalCtrl.getTop();
  //     //     if (element) {
  //     //         element.dismiss();
  //     //         return;
  //     //     }
  //     // } catch (error) {
  //     //     console.log(error);

  //     // }

  //     // // close side menua
  //     // try {
  //     //     const element = await this.menu.getOpen();
  //     //     if (element !== null) {
  //     //         this.menu.close();
  //     //         return;

  //     //     }

  //     // } catch (error) {

  //     // }

  //     // this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
  //     //   if (outlet && outlet.canGoBack()) {
  //     //     this.utilService.success_msg('in');
  //     //     outlet.pop();
  //     //   } else if (this.router.url === '/home') {
  //     //     // if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
  //     //     this.utilService.success_msg('in home');
  //     //     //     // this.platform.exitApp(); // Exit from app
  //     //     //     navigator['app'].exitApp(); // work for ionic 4

  //     //     // } else {
  //     //     this.appexistalert();
  //     //     //     this.lastTimeBackPress = new Date().getTime();
  //     //     // }
  //     //   }
  //     // });

  //     console.log('Back press handler!');
  //     if (this.loc.isCurrentPathEqualTo('/home')) {
  //       // Show Exit Alert!
  //       console.log('Show Exit Alert!');
  //       navigator['app'].exitApp();
  //       processNextHandler();
  //     } else {
  //       // Navigate to back page
  //       console.log('Navigate to back page');
  //       this.loc.back();
  //     }

  //   });

  //   this.platform.backButton.subscribeWithPriority(-1, () => {
  //     if (!this.routerOutlet.canGoBack()) {
  //       navigator['app'].exitApp();
  //     }
  //   });
  // }


  initializeApp() {



  }

  binddata() {
    if (localStorage.getItem('UserId') != undefined && localStorage.getItem('UserId') != '' && localStorage.getItem('UserId') != null) {
      //this.utilService.err_msg('error',localStorage.getItem('UserId'),3000);
      this.userService.UserById(localStorage.getItem('UserId')).subscribe(
        (data: any) => {
          //this.utilService.err_msg('error','in function',3000);
          console.log(data);
          var r = data[0];
          this.mobileno = r.MobileNo;
          this.joindate = r.CreatedOn;
          localStorage.setItem('JoinDate', r.CreatedOn);
          this.name = r.Name;
          localStorage.setItem('Name', r.Name);
          localStorage.setItem('UserId', r.UserId);
          this.profileimage = r.ProfileImg != null && r.ProfileImage != '' && r.ProfileImg != undefined ? r.ProfileImg : '../../../assets/images/user.png';
          this.userService.GetPrimaryBusinessbyUserId(r.UserId).subscribe(
            (data: any) => {
              console.log(data);
              if (data != null) {
                var t = data[0];
                localStorage.setItem('CategoryId', t.CategoryId);
                this.utilService.hide();
              }
              else {
                this.utilService.hide();
              }
            },
            (err: any) => {
              this.utilService.hide();
            });
          this.router.navigate(['tabs'], { replaceUrl: true });
        },
        (err: any) => {

        });
    }
    else {
      this.userService.logoutuser(localStorage.getItem('UserId'), localStorage.getItem('DeviceId')).subscribe(
        (data: any) => {
          console.log(data);
          if (data != null && data.message == "Success") {
            //this.utilService.err_msg('error','logout',3000);
            this.mobileno = '';
            this.joindate = '';
            this.name = '';
            this.profileimage = '../../../assets/images/user.png';
            localStorage.setItem('MobileNo', '');
            localStorage.setItem('JoinDate', '');
            localStorage.setItem('Name', '');
            localStorage.setItem('UserId', '');
            localStorage.setItem('CategoryId', '');
            localStorage.setItem('DeviceId','');
            localStorage.clear();
            this.router.navigate(['sign-in'], { replaceUrl: true });
          }
        },
        (err: any) => {

        });


    }
  }

  home() {
    this.router.navigate(['tabs/tab1'], { replaceUrl: true });
  }
  profile() {
    this.router.navigate(['tabs/tab5']);
  }
  chat() {
    this.router.navigate(['tabs/tab4']);
  }
  categorylist() {
    this.router.navigate(['categorylist']);
  }
  productlist() {
    this.router.navigate(['productlist']);
  }
  orders() {
    this.router.navigate(['order']);
  }
  notifications() {
    this.router.navigate(['my-orders']);
  }
  register() {
    this.router.navigate(['addcustomer']);
  }
  about() {
    this.router.navigate(['about']);
  }
  aboutmember() {
    this.router.navigate(['member']);
  }
  help() {
    this.router.navigate(['help']);
  }
  privacy() {
    this.router.navigate(['policy']);
  }
  term() {
    this.router.navigate(['terms']);
  }
  rating() {
    this.router.navigate(['my-cards']);
  }

  faq(){
    this.router.navigate(['faq']);
  }

  logout() {
    //this.utilService.success_msg("logout page : " + localStorage.getItem('DeviceId'));
    //this.getUniqueDeviceID();
   
      //this.utilService.success_msg(JSON.stringify(uuid));
      this.UniqueDeviceID = Device.getId();
      this.userService.logoutuser(localStorage.getItem('UserId'), this.UniqueDeviceID).subscribe(
        (data: any) => {
          console.log(data);
          //this.utilService.success_msg(JSON.stringify(data));
          if (data != null && data.message == "Success") {
            this.mobileno = '';
            this.joindate = '';
            this.name = '';
            localStorage.setItem('MobileNo', '');
            localStorage.setItem('JoinDate', '');
            localStorage.setItem('Name', '');
            localStorage.setItem('UserId', '');
            localStorage.setItem('CategoryId', '');
            localStorage.setItem('DeviceId','');
            localStorage.clear();
            this.router.navigate(['sign-in'], { replaceUrl: true });
          }
        },
        (err: any) => {
  
        });

   



  }
  event() {
    this.router.navigate(['event']);
  }

  getUniqueDeviceID() {
   
        this.UniqueDeviceID = Device.getId();
        localStorage.setItem('DeviceId',this.UniqueDeviceID);
      
  }


  // getID_UID(type) {
  //   if (type == "IMEI") {
  //     return this.uid.IMEI;
  //   } else if (type == "ICCID") {
  //     return this.uid.ICCID;
  //   } else if (type == "IMSI") {
  //     return this.uid.IMSI;
  //   } else if (type == "MAC") {
  //     return this.uid.MAC;
  //   } else if (type == "UUID") {
  //     return this.uid.UUID;
  //   }
  // }


  getPermission() {
    this.androidPermissions.checkPermission(
      this.androidPermissions.PERMISSION.READ_PHONE_STATE
    ).then(res => {
      if (res.hasPermission) {

      } else {
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE).then(res => {
          //alert("Persmission Granted Please Restart App!");
        }).catch(error => {
          //alert("Error! "+error);
        });
      }
    }).catch(error => {
      //alert("Error! "+error);
    });
  }
}
